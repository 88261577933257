import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  Button,
  CardHeader,
  ConfirmDeleteModal,
  DrawerForm,
  DrawerFormProps,
  FormControl,
  FormProvider,
  Icon,
  Loadable,
  TextField,
  Tooltip,
  useForm,
  useModal,
} from '@fleet/shared';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'store/utils';
import {
  createOrUpdateAttribute,
  deleteAttribute,
  getAttribute,
  getAttributes,
  setAttribute,
} from 'features/attribute/attributeActions';
import { currentAttributeLoadingSelector } from 'features/loading/loadingSelectors';
import {
  ButtonGroup,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { currentAttributeSelector } from 'features/attribute/attributeSelectors';
import { TransField } from 'i18n/trans/field';
import { AttributeAccordion } from 'routes/Attributes/Accordion/AttributeAccordion';
import { TransAlert } from 'i18n/trans/alert';
import { useAlert } from 'react-alert';
import { TransModal } from 'i18n/trans/modal';
import { StopAttribute } from 'dto/stop';

export const AttributeDetails: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  const currentAttribute = useSelector(currentAttributeSelector);
  const loading = useSelector(currentAttributeLoadingSelector);
  const { action, id } = useParams<{ action: string; id?: string }>();
  const { open: isOpen, onOpen, onClose } = useModal();
  const isEditing = useMemo(
    () => action === 'edit' && Boolean(id),
    [action, id]
  );

  const closeDrawer = useCallback(() => {
    history.replace('/attributes');
  }, [history]);

  useEffect(() => {
    dispatch(setAttribute());
    if (isEditing && id) {
      dispatch(getAttribute(id)).unwrap().catch(closeDrawer);
    }

    return () => {
      dispatch(setAttribute());
    };
  }, [dispatch, closeDrawer, id, isEditing]);

  const handleCloseEditForm: DrawerFormProps['onClose'] = useCallback(
    (_, reason) => {
      if (reason === 'close') {
        dispatch(setAttribute());
        closeDrawer();
      }
    },
    [dispatch, closeDrawer]
  );

  const initialValues = useMemo(
    () => ({
      ...currentAttribute,
    }),
    [currentAttribute]
  );

  const onSubmit = useCallback(
    async (values: StopAttribute) => {
      const attribute = await dispatch(
        createOrUpdateAttribute(values)
      ).unwrap();

      if (!values.id) {
        history.replace(`/attributes/edit/${attribute.id}`);
        alert.success(<TransAlert i18nKey="attributeCreated" />);
      } else {
        alert.success(<TransAlert i18nKey="attributeUpdated" />);
      }

      dispatch(getAttributes());
    },
    [alert, dispatch, history]
  );

  const { form, handleSubmit, dirty, submitting } = useForm<StopAttribute>({
    initialValues,
    onSubmit,
    subscription: { dirty: true, submitting: true },
  });

  const handleDelete = useCallback(async () => {
    await dispatch(deleteAttribute(id!)).unwrap();
    await dispatch(getAttributes());
    alert.success(<TransAlert i18nKey="attributeDeleted" />);
    closeDrawer();
  }, [alert, closeDrawer, dispatch, id]);

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  return (
    <>
      <ConfirmDeleteModal
        handleDelete={handleDelete}
        title={<TransModal i18nKey="attributeDeletionTitle" />}
        description={<TransModal i18nKey="attributeDeletionDescription" />}
        isOpen={isOpen}
        onClose={onClose}
      />
      <DrawerForm open onClose={handleCloseEditForm}>
        <Loadable loading={loading}>
          <FormProvider {...form}>
            <form onSubmit={handleSubmit}>
              <CardHeader
                isLight
                title={
                  <Typography variant="subtitle">
                    {isEditing ? (
                      currentAttribute?.name
                    ) : loading ? (
                      <>&nbsp;</>
                    ) : (
                      <TransTitle i18nKey="stopAttribute" />
                    )}
                  </Typography>
                }
                action={
                  <ButtonGroup>
                    {isEditing && (
                      <Button
                        variant="text"
                        color="error"
                        startIcon={<Icon name="trash" />}
                        onClick={onOpen}
                      >
                        <TransButton i18nKey="delete" />
                      </Button>
                    )}
                    <IconButton aria-label="close" onClick={closeDrawer}>
                      <Tooltip
                        content={<TransButton i18nKey="close" />}
                        delay={500}
                      >
                        <Icon name="close" size={24} />
                      </Tooltip>
                    </IconButton>
                  </ButtonGroup>
                }
              />
              <CardContent>
                <Grid container columns={2} spacing={2}>
                  <Grid item xs={1}>
                    <TextField
                      label={<TransField i18nKey="name" />}
                      name="name"
                      required
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <TextField
                      label={<TransField i18nKey="code" />}
                      name="code"
                      required
                    />
                  </Grid>
                  <Grid item xs="auto" sx={{ ml: 'auto' }}>
                    <Stack direction="row" flexWrap="nowrap">
                      <FormControl label="&nbsp;">
                        <Button
                          variant="text"
                          color="primary"
                          sx={{ whiteSpace: 'nowrap' }}
                          {...(!isEditing && { onClick: closeDrawer })}
                          {...(isEditing && {
                            onClick: handleReset,
                            disabled: !dirty,
                          })}
                        >
                          <TransButton
                            i18nKey={isEditing ? 'resetChanges' : 'cancel'}
                          />
                        </Button>
                      </FormControl>
                      <FormControl label="&nbsp;">
                        <Button
                          type="submit"
                          variant="contained"
                          icon={id ? 'check' : 'plus'}
                          disabled={submitting}
                        >
                          <TransButton i18nKey={id ? 'save' : 'create'} />
                        </Button>
                      </FormControl>
                    </Stack>
                  </Grid>
                </Grid>
              </CardContent>
            </form>
          </FormProvider>
          {isEditing && <AttributeAccordion />}
        </Loadable>
      </DrawerForm>
    </>
  );
};
