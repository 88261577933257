import { createAsyncThunk } from 'store/utils';
import {
  BusinessEntity,
  City,
  ClassificationGroupItem,
  Country,
  County,
  STANDARD_CLASSIFICATION_GROUPS,
} from 'dto/classification';
import { setCurrentBusinessEntity } from 'features/common/commonActions';
import qs from 'qs';
import { api } from '@fleet/shared';
import { CodeList } from 'dto/codeList';
import { getAttributes } from 'features/attribute/attributeActions';

export const getClassificationGroups = createAsyncThunk<
  Array<ClassificationGroupItem>
>(
  'getClassificationGroups',
  async () =>
    (
      await api.get<{ items: Array<ClassificationGroupItem> }>(
        `/classifications/groups${qs.stringify(
          {
            ids: Object.values(STANDARD_CLASSIFICATION_GROUPS),
          },
          {
            addQueryPrefix: true,
            skipNulls: true,
            arrayFormat: 'comma',
          }
        )}`,
        {
          baseURL: process.env.REACT_APP_API_BD,
        }
      )
    ).data.items
);

export const getBusinessEntities = createAsyncThunk<Array<BusinessEntity>>(
  'classification/businessEntities',
  async (_, { getState, dispatch }) => {
    const {
      data: { items: businessEntities },
    } = await api.get<{ items: BusinessEntity[] }>('/users/business-entities', {
      baseURL: process.env.REACT_APP_API_OM,
    });

    const defaultBusinessEntity =
      businessEntities.find(({ isMain }) => isMain) || businessEntities[0];
    dispatch(
      setCurrentBusinessEntity(
        getState().common.currentBusinessEntityId || defaultBusinessEntity.id
      )
    );

    // only ORGANIZATION type of business-entities are relevant for line-network management
    return businessEntities.filter(
      ({ contactType }) => contactType === 'ORGANIZATION'
    );
  }
);

export const getCountries = createAsyncThunk<Array<Country>>(
  'countries',
  async () =>
    (
      await api.get('/countries', {
        baseURL: process.env.REACT_APP_API_BD,
      })
    ).data.items
);

export const getCounties = createAsyncThunk<Array<County>>(
  'counties',
  async () =>
    (
      await api.get('/counties', {
        baseURL: process.env.REACT_APP_API_BD,
      })
    ).data.items
);

export const getCities = createAsyncThunk<Array<City>>(
  'cities',
  async () =>
    (
      await api.get('/cities', {
        baseURL: process.env.REACT_APP_API_BD,
      })
    ).data.items
);

export const getStopCodeList = createAsyncThunk<Array<CodeList>>(
  'codeList',
  async () => (await api.get(`/code-lists/stops?limit=99999`)).data.items
);

export const getClassifications = createAsyncThunk(
  'getClassifications',
  async (_, store) => {
    await Promise.all([
      store.dispatch(getCountries()),
      store.dispatch(getCounties()),
      store.dispatch(getCities()),
      store.dispatch(getStopCodeList()),
      store.dispatch(getAttributes()),
      store.dispatch(getClassificationGroups()),
    ]);
  }
);
