import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransField = createTrans({
  cityParish: <Trans i18nKey="field.cityParish" defaults="City/Parish" />,
  code: <Trans i18nKey="field.code" defaults="Code" />,
  country: <Trans i18nKey="field.country" defaults="Country" />,
  county: <Trans i18nKey="field.county" defaults="County" />,
  email: <Trans i18nKey="field.email" defaults="Email" />,
  latitude: <Trans i18nKey="field.latitude" defaults="Latitude" />,
  longitude: <Trans i18nKey="field.longitude" defaults="Longitude" />,
  minTransferTime: (
    <Trans
      i18nKey="field.minTransferTimeInMinutes"
      defaults="Minimum transfer time (min)"
    />
  ),
  multiLeg: <Trans i18nKey="field.multiLeg" defaults="Multileg hub" />,
  name: <Trans i18nKey="field.name" defaults="Name" />,
  phone: <Trans i18nKey="field.phone" defaults="Phone" />,
  searchable: <Trans i18nKey="field.searchable" defaults="Searchable" />,
  state: <Trans i18nKey="field.state" defaults="State" />,
  street: <Trans i18nKey="field.street" defaults="Street" />,
  streetHouseNumber: (
    <Trans i18nKey="field.streetHouseNumber" defaults="Street, house number" />
  ),
  translations: <Trans i18nKey="field.translations" defaults="Translations" />,
  timeZone: <Trans i18nKey="field.timeZone" defaults="Time zone" />,
  wheelchair: (
    <Trans i18nKey="field.wheelchair" defaults="Wheelchair accessible" />
  ),
  zipCode: <Trans i18nKey="field.zipCode" defaults="Zip code" />,
});
