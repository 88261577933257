import { PaginationParams } from '@fleet/shared/dto/pagination';
import { Classifier } from '@fleet/shared/dto/classifier';

export interface StopCode {
  id: string;
  codeListId: string;
  code: string;
}

export interface StopPlatform {
  id: string;
  name: string;
  streetAddress: string;
  latitude: number;
  longitude: number;
  isWheelchairAccessible: boolean;
  localizations: Array<StopPlatformLocalization>;
}

export interface StopPlatformLocalization extends Classifier {
  culture: Classifier;
  streetAddress: string;
}

export interface StopAttribute extends Classifier {
  code: string;
  localizations: Array<StopAttributeLocalization>;
}

export interface StopAttributeLocalization {
  id: number;
  description: string;
  cultureId: string;
}

export interface StopLocalization extends Classifier {
  stopId: number;
  streetAddress: string;
  culture: Classifier;
}

export interface StopAddress {
  street: string;
  zipCode: string;
  city: string;
  county: string;
  country: {
    id: string;
    name: string;
  };
}

export interface StopAddressPayload extends Omit<StopAddress, 'country'> {
  countryId: string;
}

export interface Stop {
  id: number;
  guid: string;
  cityId: number | null;
  cityName: string;
  countyId: number | null;
  countryName: string;
  countryId: number;
  countyName: string;
  codes: Array<StopCode>;
  name: string | null;
  email: string | null;
  phone: string | null;
  streetAddress: string | null;
  zipCode: string | null;
  latitude: number;
  longitude: number;
  isMultiLegHub: boolean;
  minTransferTimeInMinutes: number | null;
  isWheelChairAccessible: boolean;
  obtSmallRadius: number | null;
  obtLargeRadius: number | null;
  timeZone: string | null;
  localizations: Array<StopLocalization>;
  platforms: Array<StopPlatform>;
  attributes: Array<StopAttribute>;
  state: string | null;
}

export interface StopPayload extends Omit<Stop, 'address' | 'codes'> {
  address: StopAddressPayload;
}

export interface StopSearchFilter extends PaginationParams {
  name: string;
  countryId: string;
  countyName: string;
  cityName: string;
  streetAddress: string;
}

export const stopSearchFilterRequiredFields: Array<
  keyof Partial<StopSearchFilter>
> = ['name', 'countyName', 'cityName', 'streetAddress'];
