import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransSubtitle = createTrans({
  attributes: <Trans i18nKey="subtitle.attributes" defaults="Attributes" />,
  codes: <Trans i18nKey="subtitle.codes" defaults="Codes" />,
  langDependantParams: (
    <Trans
      i18nKey="subtitle.langDependantParams"
      defaults="Language dependent parameters"
    />
  ),
  newStop: <Trans i18nKey="subtitle.newStop" defaults="New stop" />,
  platforms: <Trans i18nKey="subtitle.platforms" defaults="Platforms" />,
  search: <Trans i18nKey="subtitle.search" defaults="Search" />,
  searchResults: (
    <Trans i18nKey="subtitle.searchResults" defaults="Search results" />
  ),
  stopQty: <Trans i18nKey="subtitle.stopQty" defaults="{{num}} stops" />,
});
