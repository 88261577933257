import { FC, useCallback, useEffect, useMemo } from 'react';
import { Typography, CardContent, Divider, Stack, Button } from '@mui/material';
import { useDispatch, useSelector } from 'store/utils';
import { SearchResult, Table, TableColumns } from '@fleet/shared';
import { useTable, usePagination, useRowSelect } from 'react-table';
import { useRowActive } from '@fleet/shared/hooks/useRowActive';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Stop } from 'dto/stop';
import { useRowSelectCheckbox } from '@fleet/shared/hooks';
import {
  stopsFilterSelector,
  stopsSelector,
} from 'features/stop/stopSelectors';
import { StopsSearchForm } from 'routes/Stops/StopsSearchForm';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import { deleteStops, getStopsList } from 'features/stop/stopActions';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { stopListLoadingSelector } from 'features/loading/loadingSelectors';

interface StopsTableProps {}

export const StopsTable: FC<StopsTableProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(stopListLoadingSelector);
  const stops = useSelector(stopsSelector);
  const filter = useSelector(stopsFilterSelector);
  const { id } = useParams<{ action: 'create' | 'edit'; id?: string }>();
  const getPage = useCallback(
    (pageSize: number) => {
      if (stops) {
        const { limit = pageSize, offset } = stops;
        return offset / limit;
      }
      return 0;
    },
    [stops]
  );
  const data = useMemo(() => stops?.items ?? [], [stops]);
  const columns = useMemo<TableColumns<Stop>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="stopName" />,
        Cell: ({
          row: {
            original: { id, name },
          },
        }) => <Link to={`/stops/edit/${id}`}>{name}</Link>,
      },
      {
        id: 'address',
        accessor: (row) => row.countryName,
        Header: <TransTableHead i18nKey="country" />,
      },
      {
        id: 'county',
        accessor: (row) => row.countyName,
        Header: <TransTableHead i18nKey="county" />,
      },
      {
        id: 'city',
        accessor: (row) => row.cityName,
        Header: <TransTableHead i18nKey="cityParish" />,
      },
      {
        id: 'street',
        accessor: (row) => row.streetAddress,
        Header: <TransTableHead i18nKey="streetHouseNumber" />,
      },
    ],
    []
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(getStopsList({ ...filter, ...paginationParams })).unwrap(),
    [dispatch, filter]
  );
  const initialState = useMemo(
    () => ({ pageSize: 10, activeRowId: id, filter }),
    [id, filter]
  );
  const getRowId = useCallback((row: Stop) => `${row.id}`, []);
  const table = useTable(
    {
      data,
      columns,
      initialState,
      pageCount: -1,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
      total: stops?.totalCount,
      getRowId,
    },
    usePagination,
    useRowActive,
    useRowSelect,
    useRowSelectCheckbox
  );
  const {
    activeFlatRow,
    state: { selectedRowIds },
    resetRowActive,
  } = table;

  const selectedStopIds = useMemo(
    () => Object.keys(selectedRowIds).map((id) => Number(id)),
    [selectedRowIds]
  );

  const removeSelectedStops = useCallback(async () => {
    await dispatch(deleteStops(selectedStopIds));
    dispatch(getStopsList());
  }, [dispatch, selectedStopIds]);

  useEffect(() => {
    if (activeFlatRow) {
      history.push(`/stops/edit/${activeFlatRow.original.id}`);
    }
  }, [activeFlatRow, history]);

  useEffect(() => {
    if (!id) {
      resetRowActive();
    }
  }, [id, dispatch, resetRowActive]);

  return (
    <>
      <StopsSearchForm />
      <Divider />
      <SearchResult results={data.length} loading={loading}>
        <Table
          caption={
            <>
              <CardContent>
                <Stack direction="row" alignItems="center">
                  <Typography variant="subtitle" fontWeight="700">
                    <TransSubtitle i18nKey="searchResults" />
                  </Typography>
                  {Boolean(stops?.totalCount) && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ ml: 2 }}
                    >
                      <TransSubtitle
                        i18nKey="stopQty"
                        values={{ num: stops?.totalCount }}
                      />
                    </Typography>
                  )}
                  {Boolean(false && !!selectedStopIds.length) && (
                    <Button
                      // TODO, wait for BE implementation
                      // https://youtrack.tsolutions.co/issue/BR-44922#focus=Comments-58-187964.0-0, point 11
                      // Can't remove created stop, API returns 405 because endpoint does not exist. Please remove this button from UI - not OK, still reproducible
                      startIcon={<Icon name="trash" />}
                      sx={{ ml: 'auto', px: 1, py: 0 }}
                      onClick={removeSelectedStops}
                      color="error"
                    >
                      <TransButton i18nKey="deleteSelected" />
                    </Button>
                  )}
                </Stack>
              </CardContent>
            </>
          }
          table={table}
        />
      </SearchResult>
    </>
  );
};
