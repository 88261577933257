import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import {
  ConfirmDeleteModal,
  FormProvider,
  Icon,
  Table,
  TableColumns,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useModal,
  useRowEditActions,
} from '@fleet/shared';
import { useDispatch, useSelector } from 'store/utils';
import { useRowSelect } from 'react-table';
import { Button, Stack } from '@mui/material';
import { TransAlert } from 'i18n/trans/alert';
import { useAlert } from 'react-alert';
import { TransButton } from 'i18n/trans/button';
import { TransTableHead } from 'i18n/trans/table';
import {
  useClassificationMap,
  useClassificationOptions,
} from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { TransModal } from 'i18n/trans/modal';
import { currentAttributeSelector } from 'features/attribute/attributeSelectors';
import {
  deleteAttributeLocalization,
  createOrUpdateAttributeLocalization,
} from 'features/attribute/attributeActions';
import { StopAttributeLocalization } from 'dto/stop';

export const AttributeLocalizations: FC = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const currentStopAttribute = useSelector(currentAttributeSelector);
  const localizations = useMemo(
    () => currentStopAttribute?.localizations ?? [],
    [currentStopAttribute?.localizations]
  );
  const cultures = useClassificationMap(ClassificationGroup.CULTURE);
  const cultureOptions = useClassificationOptions(ClassificationGroup.CULTURE);
  const { open: isOpen, onOpen, onClose } = useModal();
  const usedCultures = useMemo(
    () => localizations.map(({ cultureId }) => cultureId),
    [localizations]
  );
  const availableCultureOptions = useMemo(
    () => cultureOptions.filter(({ value }) => !usedCultures.includes(value)),
    [cultureOptions, usedCultures]
  );

  const columns: TableColumns<StopAttributeLocalization> = useMemo(
    () => [
      {
        Header: <TransTableHead i18nKey="language" />,
        accessor: 'cultureId',
        type: 'select',
        editableProps: ({ value }) => ({
          options: [
            value && { value, label: cultures.get(value) },
            ...availableCultureOptions,
          ].filter(Boolean),
        }),
      },
      {
        Header: <TransTableHead i18nKey="description" />,
        accessor: 'description',
      },
    ],
    [availableCultureOptions, cultures]
  );
  const { form } = useForm<{ rows: Array<StopAttributeLocalization> }>({
    initialValues: {
      rows: localizations,
    },
  });

  const handleRowUpdate = useCallback(
    async (values: StopAttributeLocalization) => {
      dispatch(createOrUpdateAttributeLocalization(values));
    },
    [dispatch]
  );

  const onRowsRemoved = useCallback(
    async (rows: Array<StopAttributeLocalization>) => {
      const ids = rows.map((row) => row.id);
      await dispatch(deleteAttributeLocalization(ids)).unwrap();
      alert.success(<TransAlert i18nKey="attributeLocalizationDeleted" />);

      onClose();
    },
    [alert, onClose, dispatch]
  );

  const table = useFormTable<StopAttributeLocalization>(
    {
      data: localizations,
      columns,
      form,
      onRowUpdate: handleRowUpdate,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemoved,
  });

  return (
    <>
      <ConfirmDeleteModal
        handleDelete={removeSelectedRows}
        title={<TransModal i18nKey="attributeLocalizationDeletionTitle" />}
        description={
          <TransModal i18nKey="attributeLocalizationDeletionDescription" />
        }
        isOpen={isOpen}
        onClose={onClose}
      />
      <FormProvider {...form}>
        <Table
          caption={
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="end"
                sx={{ mb: 1, gap: '10px' }}
              >
                <Button
                  startIcon={<Icon name="delete" />}
                  onClick={onOpen}
                  disabled={!Object.keys(table.state.selectedRowIds).length}
                  color="error"
                >
                  <TransButton i18nKey="deleteSelected" />
                </Button>
                <Button startIcon={<Icon name="plus" />} onClick={addRow}>
                  <TransButton i18nKey="addNew" />
                </Button>
              </Stack>
            </Stack>
          }
          getTableProps={{ sx: { tableLayout: 'fixed' } }}
          getHeaderProps={{ style: { backgroundColor: 'white' } }}
          table={table}
        />
      </FormProvider>
    </>
  );
};
