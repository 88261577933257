import { FormProvider, Icon, formSubmit, useForm } from '@fleet/shared';
import { Button, Grid, Stack } from '@mui/material';
import { TextField, SelectField } from '@fleet/shared/form';
import { FormControl } from '@fleet/shared/mui/FormControl';
import { useDispatch, useSelector } from 'store/utils';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { useCallback } from 'react';
import { SearchForm } from '@fleet/shared';
import { StopSearchFilter, stopSearchFilterRequiredFields } from 'dto/stop';
import { stopsFilterSelector } from 'features/stop/stopSelectors';
import { getStopsList } from 'features/stop/stopActions';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { ClassificationGroup } from 'dto/classification';
import { Config as FormConfig } from 'final-form';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _pick from 'lodash/pick';
import { TransAlert } from 'i18n/trans/alert';

export const StopsSearchForm = () => {
  const countryOptions = useClassificationOptions(ClassificationGroup.COUNTRY);

  const dispatch = useDispatch();
  const filter = useSelector(stopsFilterSelector);
  const onSubmit = useCallback<FormConfig<StopSearchFilter>['onSubmit']>(
    (values) => {
      (document.activeElement as HTMLInputElement)?.blur?.();
      if (
        _isEmpty(
          _filter(_pick(values, stopSearchFilterRequiredFields), Boolean)
        )
      ) {
        return stopSearchFilterRequiredFields.reduce(
          (acc, name) => ({
            ...acc,
            [name]: <TransAlert i18nKey="fillAtLeastOne" />,
          }),
          {}
        );
      }
      return formSubmit(async () => {
        await dispatch(getStopsList({ ...values, offset: 0 }));
      });
    },
    [dispatch]
  );

  const { form, handleSubmit, dirty, submitting } = useForm<StopSearchFilter>({
    initialValues: filter,
    onSubmit,
    subscription: { dirty: true, submitting: true },
  });
  const handleFilterReset = useCallback(() => {
    form.restart({});
    dirty && form.submit();
  }, [dirty, form]);

  return (
    <SearchForm title={<TransSubtitle i18nKey="search" />}>
      <FormProvider {...form}>
        <Grid
          component="form"
          container
          columns={5}
          spacing={4}
          rowSpacing={1}
          onSubmit={handleSubmit}
        >
          <Grid item xs={1}>
            <TextField name="name" label={<TransField i18nKey="name" />} />
          </Grid>
          <Grid item xs={1}>
            <SelectField
              showEmptyOption
              name="countryId"
              label={<TransField i18nKey="country" />}
              options={countryOptions}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              name="countyName"
              label={<TransField i18nKey="county" />}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              name="cityName"
              label={<TransField i18nKey="cityParish" />}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              name="streetAddress"
              label={<TransField i18nKey="streetHouseNumber" />}
            />
          </Grid>
          <Grid item xs="auto" sx={{ ml: 'auto' }}>
            <Stack direction="row" spacing={2}>
              <FormControl label="&nbsp;" labelPosition="top">
                <Button
                  sx={{ whiteSpace: 'nowrap' }}
                  onClick={handleFilterReset}
                >
                  <TransButton i18nKey="resetFilters" />
                </Button>
              </FormControl>
              <FormControl label="&nbsp;" labelPosition="top">
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<Icon name="search" />}
                  disabled={!dirty || submitting}
                >
                  <TransButton i18nKey="search" />
                </Button>
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
      </FormProvider>
    </SearchForm>
  );
};
