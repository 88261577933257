import { FC, useCallback, useMemo } from 'react';
import { Button, Stack } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { Icon } from '@fleet/shared/mui';
import { TransTableHead } from 'i18n/trans/table';
import {
  createUpdateStopCode,
  deleteStopCodes,
  getStopCodes,
} from 'features/stop/stopActions';
import { StopCode } from 'dto/stop';
import { useRowSelect, useFilters } from 'react-table';
import { useDispatch, useSelector } from 'store/utils';
import {
  FormProvider,
  useForm,
  Table,
  TableColumns,
  useFormTable,
  useRowEditActions,
  useIndeterminateRowSelectCheckbox,
} from '@fleet/shared';
import {
  useClassificationMap,
  useClassificationOptions,
} from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { useFormTableControls } from '@fleet/shared/hooks';
import { currentStopSelector } from 'features/stop/stopSelectors';
import isEmpty from 'lodash/isEmpty';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';

interface StopFormCodesProps {}

export const StopFormCodes: FC<StopFormCodesProps> = () => {
  const dispatch = useDispatch();

  const { guid: stopId, codes } = useSelector(currentStopSelector)!;
  const stops = useClassificationMap(ClassificationGroup.STOP_CODE_LIST);
  const stopCodeList = useClassificationOptions(
    ClassificationGroup.STOP_CODE_LIST
  );

  const usedStopCodes = useMemo(
    () => codes.map(({ codeListId }) => codeListId),
    [codes]
  );

  const availableStopCodeOptions = useMemo(
    () =>
      stopCodeList.filter(
        ({ value }) => !usedStopCodes.includes(value as string)
      ),
    [stopCodeList, usedStopCodes]
  );

  const columns: TableColumns<StopCode> = useMemo(
    () => [
      {
        accessor: 'codeListId',
        Header: <TransTableHead i18nKey="stopCodeListId" />,
        type: 'select',
        editableProps: ({ value }) => ({
          options: [
            value && { value, label: stops.get(value) },
            ...availableStopCodeOptions,
          ].filter(Boolean),
        }),
        width: '25%',
      },
      {
        accessor: 'code',
        Header: <TransTableHead i18nKey="code" />,
        width: '75%',
      },
    ],
    [availableStopCodeOptions, stops]
  );
  const { form } = useForm<{ rows: Array<StopCode> }>({
    initialValues: {
      rows: codes,
    },
  });

  const alert = useAlert();
  const onRowUpdate = useCallback(
    async (value: StopCode) => {
      await dispatch(createUpdateStopCode(value)).unwrap();
      await dispatch(getStopCodes(stopId));
      alert.success(
        <TransAlert
          i18nKey={value?.id ? 'stopCodeUpdated' : 'stopCodeCreated'}
        />
      );
    },
    [alert, dispatch, stopId]
  );

  const onRowsRemove = useCallback(
    async (stopCodeList: Array<StopCode>) => {
      await dispatch(
        deleteStopCodes(stopCodeList.map(({ codeListId }) => codeListId))
      );
      alert.success(<TransAlert i18nKey="stopCodeDeleted" />);
      dispatch(getStopCodes(stopId));
    },
    [alert, dispatch, stopId]
  );

  const table = useFormTable(
    {
      data: codes,
      columns,
      form,
      intitalState: {
        hiddenColumns: ['id'],
      },
      onRowUpdate,
    },
    useFilters,
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <FormProvider {...form}>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ mb: 1 }}
      >
        <Button
          variant="text"
          color="error"
          onClick={removeSelectedRows}
          startIcon={<Icon name="delete" />}
          disabled={isEmpty(table.state.selectedRowIds)}
        >
          <TransButton i18nKey="deleteSelected" />
        </Button>
        <Button
          variant="text"
          onClick={addRow}
          startIcon={<Icon name="plus" />}
        >
          <TransButton i18nKey="addNew" />
        </Button>
      </Stack>
      <Table
        getHeaderGroupProps={{ sx: { background: 'white' } }}
        getTableProps={{ sx: { tableLayout: 'fixed' } }}
        table={table}
      />
    </FormProvider>
  );
};
