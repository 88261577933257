import { FC, useCallback, useMemo } from 'react';
import { useRowSelect } from 'react-table';
import {
  FormProvider,
  Icon,
  Table,
  TableColumns,
  useForm,
  makeClassificationOptions,
} from '@fleet/shared';
import { Button, Stack } from '@mui/material';
import {
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared/hooks';
import { TransTableHead } from 'i18n/trans/table';
import { TransButton } from 'i18n/trans/button';
import { useDispatch, useSelector } from 'store/utils';
import { currentStopSelector } from 'features/stop/stopSelectors';
import {
  createStopAttributes,
  deleteStopAttribute,
  deleteStopAttributes,
  getStopAttributes,
} from 'features/stop/stopActions';
import { StopAttribute } from 'dto/stop';
import isEmpty from 'lodash/isEmpty';
import { attributesSelector } from 'features/attribute/attributeSelectors';
import { Field } from '@fleet/shared/form';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';

interface StopFormAttributesProps {}

export const StopFormAttributes: FC<StopFormAttributesProps> = () => {
  const dispatch = useDispatch();
  const { guid: stopId, attributes: stopAttributes } =
    useSelector(currentStopSelector)!;

  const { form } = useForm<{ rows: Array<StopAttribute> }>({
    initialValues: {
      rows: stopAttributes,
    },
  });
  const attributes = useSelector(attributesSelector);
  const attributeOptions = useMemo(
    () => makeClassificationOptions(attributes),
    [attributes]
  );
  const attributesMap = useMemo(
    () => new Map(attributes.map(({ id, code, name }) => [id, { code, name }])),
    [attributes]
  );
  const usedAttributes = useMemo(
    () => stopAttributes.map(({ id }) => id),
    [stopAttributes]
  );
  const availableAttributes = useMemo(
    () =>
      attributeOptions.filter(({ value }) => !usedAttributes.includes(value)),
    [attributeOptions, usedAttributes]
  );

  const columns = useMemo<TableColumns<StopAttribute>>(
    () => [
      {
        accessor: 'id',
        type: 'select',
        editableProps: ({ value }) => ({
          options: [
            value && { value, label: attributesMap.get(value)?.name },
            ...availableAttributes,
          ].filter(Boolean),
        }),
        Header: <TransTableHead i18nKey="name" />,
        width: '40%',
      },
      {
        accessor: 'code',
        Header: <TransTableHead i18nKey="code" />,
        width: '60%',
        editableProps: { required: false },
        Cell: ({ row }) => (
          <Field<StopAttribute>
            name={`rows[${row.index}]`}
            render={({ input }) => (
              <>{attributesMap.get(input.value?.id)?.code}</>
            )}
          />
        ),
      },
    ],
    [attributesMap, availableAttributes]
  );

  const alert = useAlert();
  const handleUpdateRow = useCallback(
    async (value: StopAttribute) => {
      await dispatch(createStopAttributes(value.id)).unwrap();
      await dispatch(getStopAttributes(stopId));
      alert.success(<TransAlert i18nKey="attributeCreated" />);
    },
    [alert, dispatch, stopId]
  );

  const onRowsRemove = useCallback(
    async (attributes: Array<StopAttribute>) => {
      await dispatch(
        deleteStopAttributes(attributes.map((attribute) => attribute.id!))
      );
      alert.success(<TransAlert i18nKey="attributeDeleted" />);
      dispatch(getStopAttributes(stopId));
    },
    [alert, dispatch, stopId]
  );

  const handleDeleteRow = useCallback(
    async (value: StopAttribute) => {
      if (!value.id) return;
      await dispatch(deleteStopAttribute(value.id)).unwrap();
      await dispatch(getStopAttributes(stopId));
      alert.success(<TransAlert i18nKey="attributeDeleted" />);
    },
    [alert, dispatch, stopId]
  );

  const table = useFormTable<StopAttribute>(
    {
      data: stopAttributes,
      columns,
      form,
      onRowUpdate: handleUpdateRow,
      onRowDelete: handleDeleteRow,
      editable: false,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <FormProvider {...form}>
      <Stack direction="row" justifyContent="flex-end" sx={{ mb: 1 }}>
        <Button
          variant="text"
          color="error"
          onClick={removeSelectedRows}
          startIcon={<Icon name="delete" />}
          disabled={isEmpty(table.state.selectedRowIds)}
        >
          <TransButton i18nKey="deleteSelected" />
        </Button>
        <Button
          variant="text"
          onClick={addRow}
          startIcon={<Icon name="plus" />}
        >
          <TransButton i18nKey="addNew" />
        </Button>
      </Stack>
      <Table
        getHeaderGroupProps={{ sx: { background: 'white' } }}
        table={table}
      />
    </FormProvider>
  );
};
