import { Classifier } from '@fleet/shared/dto/classifier';

export interface ClassificationGroupItem {
  id: ClassificationKey;
  classifications: Array<Classifier>;
}

export enum ClassificationGroup {
  CULTURE = 'CULTURE',

  // !ClassificationGroups
  STOP_CODE_LIST = 'STOP_CODE_LIST',
  BUSINESS_ENTITY = 'BUSINESS_ENTITY',
  COUNTRY = 'COUNTRY',
  COUNTY = 'COUNTY',
  CITY = 'CITY',
}

export const STANDARD_CLASSIFICATION_GROUPS = [
  ClassificationGroup.CULTURE,
] as const;

export type ClassificationKey = Extract<
  ClassificationGroup,
  typeof STANDARD_CLASSIFICATION_GROUPS[keyof typeof STANDARD_CLASSIFICATION_GROUPS]
>;

export interface Country extends Classifier {
  areaPhoneCode?: string;
  code?: string;
  culture?: string;
  currency?: string;
  unixTimeZone?: string;
  vatRate?: string;
}

export interface County extends Classifier {
  areaCodeDisplayName: string;
  countryCode: string;
  phoneAreaCode: string;
  timezone: string;
}

export interface City extends Classifier {
  code: string;
  timezone: string;
  country: Classifier;
  county: Classifier;
}

export interface BusinessEntity extends Classifier<number> {
  isMain: boolean;
  contactType: string;
}
