import { createReducer } from '@reduxjs/toolkit';
import {
  getBusinessEntities,
  getCities,
  getClassificationGroups,
  getCounties,
  getCountries,
  getStopCodeList,
} from 'features/classification/classificationActions';
import type {
  BusinessEntity,
  City,
  ClassificationGroup,
  ClassificationKey,
  Country,
  County,
} from 'dto/classification';
import type { CodeList } from 'dto/codeList';
import { Classifier } from '@fleet/shared/dto/classifier';

export type ClassificationState = Record<
  ClassificationKey,
  Array<Classifier>
> & {
  [ClassificationGroup.COUNTRY]: Array<Country>;
  [ClassificationGroup.COUNTY]: Array<County>;
  [ClassificationGroup.CITY]: Array<City>;
  [ClassificationGroup.BUSINESS_ENTITY]: Array<BusinessEntity>;
  [ClassificationGroup.STOP_CODE_LIST]: Array<CodeList>;
};

const initialState = {} as ClassificationState;

export const classificationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getBusinessEntities.fulfilled, (state, action) => {
      state.BUSINESS_ENTITY = action.payload;
    })
    .addCase(getClassificationGroups.fulfilled, (state, action) => {
      action.payload.forEach((data) => (state[data.id] = data.classifications));
    })
    .addCase(getCountries.fulfilled, (state, action) => {
      state.COUNTRY = action.payload;
    })
    .addCase(getCounties.fulfilled, (state, action) => {
      state.COUNTY = action.payload;
    })
    .addCase(getCities.fulfilled, (state, action) => {
      state.CITY = action.payload;
    })
    .addCase(getStopCodeList.fulfilled, (state, action) => {
      state.STOP_CODE_LIST = action.payload;
    });
});
